/* eslint-disable import/no-anonymous-default-export */
import {
  CREATED_PICKUP,
  PICKUP_FAIL,
  CREATE_PICKUP,
  RESET,
} from '../actions/types';

const initialState = {
  loading: false,
  created: false,
  error: false,
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PICKUP:
      return {
        ...state,
        loading: true,
        created: false,
        failed: false,
        error: false,
        errorMessage: '',
      };
    case PICKUP_FAIL:
      return {
        ...state,
        loading: false,
        created: false,
        error: true,
        errorMessage: action.payload,
      };
    case CREATED_PICKUP:
      return {
        ...state,
        created: true,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
